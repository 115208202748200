export const MenuItems = [
  {
    title: 'OMFB Education Loans',
    path: '/Products',
    msg:{ testvalue: "OMFB Education Loans" },
    cName: 'dropdown-link'
  },
  {
    title: 'Commerce Loan',
    path: '/Products',
    msg:{ testvalue: "Commerce Loan" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Mobile Loans',
    path: '/Products',
    msg:{ testvalue: "OMFB Mobile Loans" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Be Better Placed Loans',
    path: '/Products',
    msg:{ testvalue: "OMFB Be Better Placed Loans" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Fight Poverty Loans',
    path: '/Products',
    msg:{ testvalue: "OMFB Fight Poverty Loans" },
    cName: 'dropdown-link'
  },
  {
    
    title: 'OMFB Salary Advance/Overdraft',
    path: '/Products',
    msg:{ testvalue: "OMFB Salary Advance/Overdraft"
  },
    cName: 'dropdown-link'
  },
  {
    title: 'Salary/Pension Earners Loan',
    path: '/Products',
    msg:{ testvalue: "Salary/Pension Earners Loan" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Agricultural Loan',
    path: '/Products',
    msg:{ testvalue: "OMFB Agricultural Loan" },
    cName: 'dropdown-link'
  }
  ,
  {
    title: 'OMFB Cottage Industry Loans',
    path: '/Products',
    msg:{ testvalue: "OMFB Cottage Industry Loans" },
    cName: 'dropdown-link'
  }
];








