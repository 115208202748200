import React from 'react';
import '../../App.css';
import  img from '../../images/contactimage.JPG';
import  img2 from '../../images/contactimage2.JPG';
import  img3 from '../../images/img-2.jpg';

export default function ContactUs() {

 
  const bgtxt={
     position: 'relative',
    display: 'block',
    float:'left',
    TextAlign: 'center',
    backgroundColor:'lightgreen',
    backgroundImage: `url(${img3})`,
    BackgroundRepeat:'no-repeat',
     height:'30%',
     width:'50%',
    
    font: 'San serif',
    fontSize:'1rem',
    padding:'5%'
  }
  const bgsidebar={
     position: 'relative',
   
    float:'right',
    alignText: 'right',
    backgroundColor:'lightgreen',
    backgroundImage: `url(${img})`,
    BackgroundRepeat:'no-repeat',
    BackgroundSize:'cover',
    BorderRadius:'50',
     height:'100%',
     width:'50%',
   
    font: 'San serif',
    fontSize:'1rem',
    padding:'15%'
  }
  const bgimg={
    position: 'relative',
  
   float:'center',
   backgroundColor:'black',
   BorderRadius:'50',
   backgroundImage: `url(${img2})`,
   backgroundRepeat:'no-repeat',
    height:'100%',
    width:'50%',
   textAlign: 'center',
   font: 'San serif',
   fontSize:'1rem',
   padding:'15%'
 }
 ;
  return (
    <div style={{display:'flex',overflow:'hidden', backgroundSize: 'cover'}}>
     <div style={bgsidebar} >
     </div>
     <div style={bgtxt} > 
      <h1>Customer Care Service</h1>	
   	 <p>Welcome to our CustomerCare section, our dedicated Customer Care representatives are available on a 24 &#8211; hour basis to attend to all your inquiries, complaints and basic service needs. 
      <br/>You can also reach us via any of our social media channels.</p>
<p>Or step into our office at the address listed below.</p>
<p><strong>Ohafia Microfinance BanK Plc.</strong><br />
#122 Arochukwu Road,<br />
Amaekpu – Ohafia,<br />
Abia State,<br />
Nigeria.</p> 
<p><strong>Telephone:</strong> 0803-468-5535, 0810-637-5353, 0803-294-1645</p>
<p><strong>email:</strong>contact@ohafiamicrofinancebankplc.com,ohafiamicrobank@gmail.com</p>
</div>
<div style={bgimg} >
</div>
</div>
  );
  }
  
	






