import React from 'react';
import '../../App.css';
import { useLocation } from 'react-router-dom'


 

export default function Products() {
  const prodbodystyle={
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  padding:'160px',
  margin:'200px',
  fontSize: '15px',
  fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
 
  }

  const location = useLocation()
  const { testvalue,testtitle } = location.state
  return(
    <>
  <div className='products' display='flex'>
  <label>

    
  {testtitle}
  </label>
  
  {testvalue==='Salary/Pension Earners Loan' && <div style={{prodbodystyle}} >
  <h1>Salary/Pension Earners Loan  </h1>
    <br/> This product is designed for salary/pension earners whose salaries/pensions are domiciled with the bank.
    <br/>The beauty of this product is that civil servants/pensioners can access the bank’s loan whether or not they 
    <br/>operate current accounts </div>}
    {testvalue==='OMFB Be Better Placed Loans' && <div style={{prodbodystyle}} >
  <h1>OMFB Be Better Placed Loans </h1>
    <br/> A product designed to establish the beginners of new business or apprentices who have successfully finished from
    <br/>their Master.  The loan is specifically for the procurement of their working tools and equipment including materials
    <br/>to start their own businesses. </div>}
    {testvalue==='OMFB Mobile Loans' && <div style={{prodbodystyle}} >
  <h1>OMFB Mobile Loans</h1>
    <br/> This is to aid our customers own their vehicles, tricycles, motorcycles, bicycles etc. 
    </div>}
{testvalue==='OMFB Education Loans' && <div style={{prodbodystyle}} >
  <h1>OMFB Education Loans </h1>
    <br/> This facility is specially designed to tackle educational challenges of our customers.
    <br/>Meanwhile, the individual must have been showing some level of seriousness with his education empowerment account, 
    <br/>particularly if he beneficiary is not a civil servant.</div>}
    {testvalue==='Commerce Loan' && <div style={{prodbodystyle}} >
  <h1>OMFB Trade & Commerce Loan </h1>
    <br/> This product is designed to meet the credit need of traders. It enhances quick access to funds to enable business
    <br/>people take advantage of time-sensitive opportunities such as supply of goods and services, execution of projects,
    <br/>contract or LPO with specific deadline.
    </div>}
  
    {testvalue==='OMFB Fight Poverty Loans' && <div style={{prodbodystyle}} >
  <h1>OMFB Fight Poverty Loans </h1>
    <br/> Strictly meant to alleviate the poverty level amongst rural dwellers who are economically active but lack the financial
    <br/>strength.  The compound/village head recommends who could be granted loans after due considerations.  However,
    <br/>they are expected to form groups to enhance cross-guaranteeing by the members before loans are disbursed. </div>}
    {testvalue==='OMFB Agricultural Loan' && <div style={{prodbodystyle}} >
  <h1>OMFB Agricultural Loan </h1>
    <br/> This is a product created to meet the needs of farmers in their various fields. The bank’s agricultural loan is granted
    <br/>under the agricultural credit guarantee scheme fund of the central bank of Nigeria in which a faithful beneficiary
    <br/>receives 40% of the total interest paid as rebate.</div>}
    {testvalue==='OMFB Cottage Industry Loans' && <div style={{prodbodystyle}} >
  <h1>OMFB Cottage Industry Loans</h1>
    <br/> This is specially created for small scale industrialists and it is a strong leverage for the self-employed and artisans
    <br/>uch as Mechanics Vulcanizers, Carpenters, Plumbers, Barbers, Welders, Hairdressers, meat/fish vendors, and food
    <br/>stuff dealers etc. to take care of their working capital needs.</div>}
    {testvalue==='OMFB Salary Advance/Overdraft' && <div style={{prodbodystyle}} >
  <h1>OMFB Salary Advance/Overdraft </h1>
    <br/> This is for individuals with regular salaries or income who need to raise cash for specific purposes before the
    <br/>expected salary date or cheque clearance. Like-wise customers with reasonable turnover who have need for short    
    <br/>term fund to meet specific obligations might go for overdraft.</div>}

  </div>
  </>
  );
}
