import React from 'react';
import '../../App.css';
import { useLocation } from 'react-router-dom'
import Pdf from '../../documents/2022-Chairmans-AGM-Statement.pdf';
import excel from '../../documents/financial-statement.xlsx';
import ppt from '../../documents/financial-statement.pptx';
export default function About() {

 
    const servicestyle={
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    padding:'160px',
    margin:'200px',
    fontSize: '0.5vw',
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
   
    }
    const location = useLocation()
  const { testvalue,testtitle } = location.state
    return(
      <>
    <div className='about' display='flex'>
    <label>
  
      
    {testtitle}
    </label>
    
    {testvalue==='Preamble' && <div style={{servicestyle}} >
    <h1>Preamble </h1>

RC 166064 – Dated 27th June, 1991
<br/>

<br/>We live in a world where:
<br/>
<br/>•	Ideas are turned into practical realities.
<br/>•	Natural resources are converted to hard cash.
<br/>•	Importers of goods become manufacturers of those goods they import.
<br/>•	Farmers are given back 40% of the interest paid on agricultural loans.
<br/>•	There is a one stop center for computer education, internet access and a whole range of secretarial business.
<br/>•	Traders, artisans, commercial drivers and market women are given assistance to realize their dreams.
<br/>•	Hard working rural and urban poor are made millionaires in three years.
<br/>•	The Return on Investment (ROI) has for the past few years been 17.5% per annum.
<br/>•	One can find harmony of human capital.
<br/>
<br/>We live in Ohafia Microfinance Bank Plc.
<br/>Join us today and get the benefits of incisive thinking.
</div>}
      {testvalue==='Profile' && <div style={{servicestyle}} >
    <h1><b style={{color:'green'}}>Profile </b></h1>
 
    <br/>Ohafia Microfinance Bank Plc previously called Ohafia Community Bank Ltd is one of the leading Microfinance Banks licensed by the Central Bank Of Nigeria.
    <br/> It was incorporated on 27th day of June, 1991 with RC 166064.

 

    <br/>It started operation on 24th July 1992 in a rented apartment at No 87 Arochukwu Road Amaekpu, Ohafia. The asset base of the bank at the time of commencement of operations was N250,000 (Two hundred and fifty thousand naira) only.  From this humble beginning the bank recorded steady growth over the years and presently has an asset base of over one billion Naira. The bank now operates from its own two corporate office buildings at No 122 Arochukwu Road, Amaekpu – Ohafia,Abia State.Ohafia Microfinance Bank Plcis one of the leading MFBs in terms of customer and asset base,Returns on investment and security of assets.
    
    <br/>We uphold the Core Values of care, Integrity, Ethics and Professionalism.  These values form the fabric of the bank and bind together the experienced management team.   
    
    <br/>Our service orientation is premised on integrity and service efficiency.
    
    <br/>The Chairman of the Board of Directors of the bank is Nnamdi Uhabia Okije a seasoned chartered accountant.
    <br/>Other members of the Board are:-

    <br/>Elder George E. Uma
    <br/>Chief Ifeanyi Ukonu. Ukoha
    <br/>Enyidiya Uwa Ojike
 
    <br/>
    <br/>The management team is composed of:-
    <br/>
    <br/>Mr. Kalu N. Oji (Managing Director)
    <br/>Pastor James Agwu (HOD Credit Administration)
    <br/>Mrs. Mabel K. Abiayi (HOD Internal Audit)
    <br/>Mr. Peter I. Nwankwo (HOD Administration)
    <br/>Mrs. Nnenna Nnoke (HOD Compliance and Risk Management)
    <br/>Mrs. Uzo U. Nchege (HOD Financial Control)
    <br/>Mrs Ijeoma Mba John (HOD Marketing)
    <br/>Mr. Ebi Kalu Ebi (HOD Operations)
    <br/>
    <br/>Our employees are one of our greatest strengths and key to the success of the bank. Employment and retention of skilled professionals is key to us as we continually attract, motivate and retain the best talents.

    <br/><b><i style={{color:'green'}}>Our Mission</i></b>

    <br/>To achieve customer satisfaction by providing reliable and efficient services through skilled, motivated and experienced professionals that also create shareholders’ value.
    <br/>

    <br/><b><i style={{color:'green'}}>Our Core Values </i></b>
    <br/>
    <br/>Honesty, Accountability, Professionalism and best corporate governance practices.  

    <br/>The bank’s objectives are consistent with those enumerated in the Microfinance Policy document of the Central Bank of Nigeria as follows:

    <br/>	Provision of timely, diversified, affordable and dependable financial services to the economically active poor.
    <br/>
    <br/>	Creation of employment opportunities and increasing the productivity and household income of the active poor thereby enhancing their standard of living.
    <br/>
    <br/>	Enhancement of service delivery to micro, small and medium enterprises (MSME).
    <br/>
    <br/>	Mobilization of savings for intermediation and rural transformation.
    <br/>
    <br/>	Promotion of linkage programmes between Microfinance Institutions, Deposit Money Banks and Development Finance Institutions.
    <br/>
    <br/>	Provision of dependable avenues for the administration of micro-credit programmes of government. 
    <br/>
    <br/><b><i style={{color:'green'}}>Operational Activities:</i></b>
    <br/>
    <br/>The bank focuses on activities in agriculture, commence, transportation etc.  All these provide opportunities for sustainable and gradual reduction in poverty.  In addition, the bank has a one-stop centre for a whole range of secretarial business, internet access, computer education and training and all forms of access to e-transactions. 
 
    <br/>The bank strives to drive these activities through specialized financial products and services.

</div>}
{testvalue==='Directors' && <div style={{servicestyle}} >
    <h1>Our Directors</h1>
    <br/>
    <br/><b> <i style={{color:'yellow'}}>NNAMDI UHABIA OJIKE – Chairman</i></b>
    <br/>
    <br/>Uhabia holds a B.Sc. Degree in Banking and Finance from the University of Nigeria, and an MBA from the University of Manchester. He is also a Chartered Accountant, a Certified Information System Auditor and an Associate member of the Chartered Institute of Taxation.
    <br/>Uhabia has over ten years’ experience working for Deloitte Nigeria and is a beneficiary of the Global Development Programme of Deloitte under which he was seconded to Deloitte in the Netherlands for a period of 18 months.
    <br/>He has enormous audit and taxation experience ranging across various industries. This includes providing audit, tax and advisory services for companies like TOTAL Nigeria Plc., Conoil Plc., Addax Petroleum Development Nigeria Limited, Glo Mobile, Vodafone Netherlands, Royal Bank of Scotland, NXP Semiconductors BV, Bloomberg etc. and other Companies in the insurance, banking, manufacturing, trading and educational institutions industry. He is currently the Managing Partner of Ojike, Okechukwu and Co., Chartered Accountants.
    <br/>Uhabia is a Fellow of Institute of Chartered Accountants of Nigeria.
    <br/>_____________________________________________________________________________________________________________________________________________________________
<br/> <i style={{color:'yellow'}}>Elder George Egbeke Uma – Director</i>
<br/>Elder George Egbeke Uma, was educated at Kalabari College, Buguma and West African Peoples Institute (WAPI), Calabar in Cross River State. He obtained his First Degree in Estate Management from the University of Nigeria, Nsukka. He worked for various Government Departments in the then Eastern Nigeria. He also attended many Seminars and Management Courses overseas, principally in Great Britain and the United States of America.
<br/>Elder Uma, worked as a Valuation Officer with the Government and rose to the rank of Deputy Chief Valuation Officer with the Imo State Government. He is currently the Principal Partner in EGBEKS UMA ASSOCIATES, a firm of Estate Valuers and Surveyors.
<br/>_________________________________________________________________________________________________________________________________________________________________
<br/><i style={{color:'yellow'}}>Mr.Ifeanyi Ukoha – Director</i>
<br/>Mr. Ifeanyi Ukoha (B.Sc., FCA) is a banker and fellow of the Institute of Chartered Accountants. He has over 20 years’ experience in Internal Audit, Commercial Banking, Central Banking and electronic payments. He had also worked for the CBN, KPMG, First Bank and Diamond bank Plc. among others and was trained by the United Nations Institute of Training and Research on Alternative Dispute Resolution. He has certifications in project management & Negotiation etc.
<br/>_________________________________________________________________________________________________________________________________________________________________
{/* <br/><i style={{color:'yellow'}}>Enyidiya Uwa Ojike – Director</i>
<br/>Uhabia holds a B.Sc. Degree in Banking and Finance from the University of Nigeria, and an MBA from the University of Manchester. He is also a Chartered Accountant, a Certified Information System Auditor and an Associate member of the Chartered Institute of Taxation.
<br/>Uhabia has over ten years’ experience working for Deloitte Nigeria and is a beneficiary of the Global Development Programme of Deloitte under which he was seconded to Deloitte in the Netherlands for a period of 18 months.
<br/>He has enormous audit and taxation experience ranging across various industries. This includes providing audit, tax and advisory services for companies like TOTAL Nigeria Plc., Conoil Plc., Addax Petroleum Development Nigeria Limited, Glo Mobile, Vodafone Netherlands, Royal Bank of Scotland, NXP Semiconductors BV, Bloomberg etc. and other Companies in the insurance, banking, manufacturing, trading and educational institutions industry. He is currently the Managing Partner of Ojike, Okechukwu and Co., Chartered Accountants.
<br/>Uhabia is a Fellow of Institute of Chartered Accountants of Nigeria. */}

      </div>}
  {testvalue==='Values & Philosophy' && <div style={{servicestyle}} >
    <h1>Values & Philosophy</h1>
    <br/>
<br/>OUR VISION:
<br/>To be the leading microfinance bank in the South East Region in the provision of financial services.
<br/>
<br/>
<br/>
<br/>OUR MISSION:
<br/>To achieve customer satisfaction by providing reliable and efficient services through skilled, motivated and experienced professionals that can create and optimize shareholders value.

<br/>
<br/>
<br/>
<br/>
<br/>OUR CORE VALUES:
<br/>
<br/>Honesty, accountability, professionalism and best corporate governance practices.
<br/>
<br/>The banks objectives are consistent with those enumerated in the microfinance policy document of the CBN as follows:
<br/>
<br/>Provision of timely, diversified, affordable and dependable financial services to the economically active poor.
<br/>Creation of employment opportunities and increasing the productivity and household income of the active poor, thereby, enhancing their standard of living.
<br/>Enhancement of service delivery to micro, small and medium enterprises (MSME).
<br/>Mobilization of savings for intermediation and rural transformation.
<br/>Promotion of linkage programmes between microfinance institutions, deposit money banks and development finance institutions.
<br/>Provision of dependable avenue for the administration of microcredit programmes of governments.
<br/>
<br/>
<br/>OPERATIONAL ACTIVITIES
<br/>
<br/>The bank focuses on activities in agriculture, commence, transportation etc. All these provide opportunities for sustainable and gradual reduction in poverty. In addition, the bank has a one-stop centre for a whole range of secretarial business, internet access, computer education and training and all forms of access to e-transactions. Our intention is to equip our sons and daughters with the necessary tools to meet the challenges of the knowledge economy of the world.
<br/>The bank strives to drive these activities through specialized financial products and services.
      </div>}
      {testvalue==='Management Team' && <div style={{servicestyle}} >
    <h1>Management Team</h1>
   
    <br/><i style={{color:'yellow'}}>KaluNnate Oji – Managing Director</i>
    <br/>
    <br/>Kalu Nnate Oji attended Ohafia High School, Ohafia, and obtained his ‘0’level certificate in division one in 1980. He holds The Nigeria Certificate in Education (NCE) with Distinction from The Federal College of Education, Pankshin, Plateau State (1984), a Bachelor of Science degree in Economics with second class honours upper division from University of Nigeria, Nsukka (1992) and a Masters of Business Administration (MBA) with emphasis in Management from Abia State University (1999).
    <br/>He started his banking career with Federal Mortgage Bank of Nigeria (1994) where he worked in various departments and gained wide experience in mortgage banking activities. He subsequently moved to Harvard Trust Savings and Loans Limited (Mortgage Bankers) in 2007 as Corporate Development Manager/Special Assistant to the Chairman.
    <br/>
    <br/>From there he joined the services of Ohafia Microfinance bank (2010) as Financial Controller and rose to the position of Managing Director in 2011.
    <br/>
    <br/>Oji has attended industry and personal development courses, workshops and seminars in Financial Management, Product Development and Marketing, Capacity Building, Human Resources Management, Managing Microfinance Banks, Risk Management, Financial Analysis and Financial Performance Monitoring etc. He is a certified Microfinance banker and a member of the Chartered Institute of Bankers of Nigeria (CIBN).
    <br/>
    <br/><i style={{color:'yellow'}}>Mrs Mabel Kalu Abiayi – INTERNAL AUDITOR AND SECRETARY TO THE BOARD</i>
    <br/>
    <br/>Mrs. Mabel Kalu Abiayi attended Federal Government Girls’ College Gboko, Benue State where she obtained her West Africa School Certificate in 1982.
    <br/>She also attended University of Lagos and obtained B.Sc(Hons) Industrial Relations and Personnel Management in 1987. 
    <br/>
    <br/>She is a Chartered Microfinance Banker and a member of Chartered Institute of Bankers of Nigeria. She has Seven (7) years experience in different units in Microfinance Banking namely; Customer Relations, Operations and Internal Audit and had undergone some training and seminar programmes.
    <br/>
    <br/>She has been a Certified Microfinance Banker of the Chartered Institute of Bankers of Nigeria since 2012.
    <br/>
    <br/>She has fourteen years' experience in different departments in Microfinance Banking namely:    Financial Control and Internal Audit 
    <br/>
    <br/>She has been the Secretary to the Board since 2010. 
    <br/>
    <br/>She equally has five years’ experience in Merchant Banking and worked in various departments as stated; Administration,Foreign Operations and Treasury with Prudent Merchant Bank Plc.
    <br/>
    <br/>She also worked with ANOVIC & ASSOCIATES AND PSAMS and has undergone some Trainings and Serminar programmes as listed below:
    <br/> <br/>Technical Capacity Building for Microfinance Banking Operations,Training For Internal Auditors For All Microfinance Banks in Nigeria,Microfinance Certification programme,Workshop/Serminar in Internal Control System in Microfinance Banks.
     Training on Statutory Compliance; Training on the International Financial Reporting Standards (IFRS) Applications,CBN Fair on Promoting Financial stability & Economic Development; Foreign Exchange Serminar,Accounting and Financial Analysis etc.
    <br/>
    <br/><i style={{color:'yellow'}}>Pastor James Agwu – Head, Credit & Development </i>
    <br/>
    <br/>Pastor James Agwu attended Army Day Secondary School Ohafia, where he obtained his Senior Secondary certificate in 1992. He equally had his National Diploma (ND) in 2000 and Higher National Diploma (HND) in 2003 both in Mechanical Engineering from AkanuIbiam Federal Polytechnic Unwana, Afikpo in Ebonyi State as well as doing his post graduate studies at the Michael Okpara University of Agriculture Umudike,Abia State.
    <br/>
    <br/>His banking experience spans over 20 years with special interest on the grass-root banking operations. Employed as a clerical officer in 1994 at the former Ohafia Community Bank Nig. Ltd (now Ohafia Microfinance Bank Plc), Pastor Agwu has steadily risen to different ranks and positions which include; Book-keeper, Acting Accountant, Secretary to the Board, Credit and Development Department, Senior Banking Executive and Head, Credit and Development, a position he is currently occupying.
    <br/>
    <br/>Pastor Agwu has enormous experiences from various Managerial and other Trainings, Seminars, workshops and courses he attended. These range from Product Development, External Auditors trainings, Risk Management, Synopsis of Accounting Systems, Financial Analysis & Projections, Loan Administration, Strategies & monitoring, customer care & Service delivery, Managing Microfinance Bank, Internal Control, Information Management etc.
    <br/>
    <br/>He is a certified Microfinance Bankers and member of the Chartered Institute of Nigeria (CIBN).
    <br/>
    <br/>
    <br/><i style={{color:'yellow'}}>Peter Ikpo Nwankwo – HoD Administration</i>
    <br/>
    <br/>Mr Peter Ikpo Nwankwo, Head, Administration Department was educated at Presbyterian Church of Nigeria School (now Community School) Ndi Uduma Awoke, Ohafia. He went to Government Technical College, Ania-Ohafia and had his WASC/GCE ‘O’ Level. He holds a Diploma (Business Administration) from Abubakar Tafawa Balewa University (ATBU), Diploma in Computer Studies from IBM’s Data Processing & Management Services (DPMS), Igbosere, Lagos. He is a Microsoft Certified System Administrator (MCSA) from NIIT, Port Harcourt. Presently, he is a final year student, Enugu State University of Technology (ESUT) for a B. Sc. (Economics) degree.
    <br/>
    <br/>Mr. Nwankwo joined the Bank in 2003 (Ohafia Community Bank Plc) having worked for UTC Nigeria PLC (Water & Power Engineering Division), a multinational conglomerate based in Apapa – Lagos. He also worked for Dorman Long & Amalgamated Engineering Co Ltd, Lagos –as an Administrative Officer supervising over 450 Staff Technicians -various professional grades/trades for construction of the 3rd largest crude oil buoyed in the world for Shell BP Unlimited.
    <br/>
    <br/>He is CIBN Microfinance Certified Practitioner (MCP), a member of the Chartered Institute of Bankers of Nigeria (CIBN)and has attended trainings in Capacity Building for Microfinance Banks,ESUSU Banking Software Implementation & MIS.
    <br/>
    <br/><i style={{color:'yellow'}}>Mrs.Uzo N. Ukpai – Financial Controller</i>
    <br/>
    <br/>Mrs Uzo N. Ukpai attended Girls High School Aba where she obtained her senoir Secondary Certificate in 1984.
     <br/>She had Diploma in Accounting in 2007 and B.Sc in Accounting Education from Imo State University 2011.
    <br/>
    <br/>She has 26 years banking experience. She started her banking career in 1992 at Ishiagu Community Bank Limited, Ebonyi State, as a Teller and later joined Ohafia Community Bank Nigeria Limited now Ohafia Microfinance Bank Plc in that capacity, She has risen steadily from that position to Supervisor, Banking Executive, Acting Financial Controller and now the Financial Controller of Ohafia Microfinance Bank Plc.
    <br/>
    <br/>She has attended various Trainings/Courses both internal and external on the operations of Microfinance Banks in Nigeria.
    <br/>
    <br/>She is a Chartered Microfinance Banker and member of Chartered Institute of Bankers of Nigeria (CIBN).
    <br/>
    <br/><i style={{color:'yellow'}}>Mrs. Nnenna Nnoke – Head, Risk Management & Compliance</i>
    <br/>
    <br/>Mrs Nnenna Nnoke had her Senior Secondary Certificate from Ohafia Girls’ Secondary School. She equally obtained Bachelor of Science degree in Accountancy from Abia State University, Uturu.
    <br/>
    <br/>Her banking experience spans over 15 years from Community Banking to Microfinance Banking. She grew in the profession from Cashier, Supervisor, Banking Executive II and now Head, Risk Management and Compliance.
    <br/>
    <br/>Mrs. Nnoke has gained huge experiences from various trainings and courses which includes Transforming Community Bank to Microfinance Bank, ESUSU Banking Software & MIS, Interim Capacity Building for Operators of Microfinance Bank in Nigeria, Enterprise-wide Risk Management Programme,Anti-Money Laundering & Counter Terrorism Finance.
    <br/>
    <br/>She is a Certified Microfinance Banker and a member of the Chartered Institute of Bankers of Nigeria (CIBN).
    <br/>
    <br/><i style={{color:'yellow'}}>Mrs. Ijeoma John Onwuka – Head, Marketing Department</i>
    <br/>
    <br/>Mrs Ijeoma John Onwuka joined Ohafia Microfinance Bank Ltd in 2009 after completing her NYSC. She had previous working experience with Trans-Atlantic Mortgage Bank PLC, and Equator Microfinance Bank Ltd, all in Yenegoa, Bayelsa State. A graduate of Urban and Regional Planning from Abia State University, Uturu, Abia State, she had her Secondary Education at Obio Comprehensive College, Rumumasi, Port-Harcourt.
    <br/>
    <br/>Since 2009, she has been heading the Marketing Department effectively with excellent results.
    <br/>
    <br/>She is a Chartered Microfinance Banker and a member of Chartered Institute of Bankers of Nigeria.
      <br/>
    <br/><i style={{color:'yellow'}}>Ebi Kalu Ebi– HoD Banking Services</i>
    <br/>
    <br/>Mr Ebi Kalu Ebi, attended Comrade Vocational Commercial School,Amaekpu Ohafia where he obtained his NABTEB in 2001. He got his B.S.C in Accountancy from Michael Okpara University of Agriculture,Umudike Umuahia in 2012.
    <br/>
    <br/>Mr Ebi Kalu Ebi started his career at Comrade Commercial Voc. School,Amaekpu Ohafia as a Teacher in 2001.He started his Banking career at Ohafia Microfinance Bank Plc in 2003 (Ohafia Community Bank Plc) where he gained wide experience in banking activities and general bank management.
    <br/>
    <br/>He has attended various Middle/Top management and Personal Development Courses,workshops and Seminars in Computer,Credit Management/Monitoring,Bank Operation and services,Equipment Maintenance/Control,Bank organization and Method,Fraud Mechanism/Detection,Human Capital Development,
     Capacity Building/Utilization,MFB Management,Risk Mitigation,Best Customer Service Delivery Approach,Product Development,Financial Analysis/Personal Monitoring etc.
    <br/>
    <br/>He is a Certified Microfinance banker (MCP) and a member of the Chartered Institute of Bankers of Nigeria (CIBN).
    <br/>
      </div>}
    
      {testvalue==='Chairmans AGM Speech' && <div style={{servicestyle}} >
    <h1>Chairman's AGM Speech 2022</h1>
    <br/>2022 AGM Speech:
    <br/>
    <br/>Distinguished Shareholders, Valued Customers,Invited Guests, My colleagues on the Board, Ladies and Gentlemen,
    <br/>It is my pleasure to welcome you all to the 31st Annual General Meeting of our Bank –Ohafia Microfinance Bank Plc 
    <br/>-and to present to you the Annual Report and Statement of Accounts for the year ended 31st December, 2021.
    <br/>
    <br/><a href = {Pdf} target = "_blank" rel="noreferrer"><i>CLICK HERE TO READ THE FULL SPEECH </i></a>
     </div>}
      {testvalue==='Financial Results' && <div style={{servicestyle}} >
    <h1>Financial Results</h1>

      
<br/>These are the Summary or analysis of the Bank’s past abridged Balance Sheet and Profit & Loss Account from Year 2009.
<br/> Do note that as from 2014 the presentation pattern is more detailed.

<br/><a href = {excel} target = "_blank" rel="noreferrer"><i>Click Here to Download Report(Excel Format)</i></a>

<br/><a href = {ppt} target = "_blank" rel="noreferrer"><i>Click Here to Download Report(Powerpoint Format) </i></a>
    
</div>}
    </div>
    </>
    );}
