import React,{useState} from 'react'
import {Link} from 'react-router-dom';
import '../components/Navbar';
import Omfblogo from '../images/Ohmfblogo.png';


function Header() { 
const [isHover,setIsHover]=useState(false);
const  handleMouseEnter=()=>{
   setIsHover(true);
}

const  handleMouseLeave=()=>{
  setIsHover(false);
}

const newslinkstyle={
  color:isHover ? 'red' : 'green',
 fontFamilys:'san serif',
 fontSize:'1.5vw',
 padding: '6px 18px',
 transition: 'all 0.3s ease-out',
backgroundColor: isHover? 'lightgreen':'lightgray',
 borderRadius: '30px',
 textDecoration:'none',
 display: 'inline-block',
textAlign: 'center',
// Center the link
position: 'absolute',
top: '6%',
left: '50%',
transform: 'translate(80%, -50%)',

// Media query for mobile phones
'@media (max-width: 768px)': {
    fontSize: '3vw', 
    padding: '2vw 6vw', 
  
},
}
  return (
   
  
     <div className="Navbar" style={{alignItems: 'flex'}}>
     
     <span><img src={Omfblogo}  alt={'logo'} height={50} width={250} style={{paddingLeft: '72px'}} /></span>
     
     <i  style={{width:'2000px',paddingLeft:'500px'}}>
            <Link
              to='/jobadvert'
             
              style={{textDecoration:'none'}} 
            >
              
             <span 
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             style={ newslinkstyle } 
             > Get Latest News | Job Advert here </span>
            </Link>
            
            <a
              href='http://ohafiamicrofinancebankplc.com/webmail'
              className='nav-links' 
             
            >
             <button className='btn' style={{background:'green', marginLeft: '10px'}}>Staff Mail</button>   
             </a>
             </i>
             
     </div>


  );
}

export default Header;
