import React from 'react';
import '../../App.css';
import { useLocation } from 'react-router-dom'

export default function Services() {

 
    const servicestyle={
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    padding:'160px',
    margin:'200px',
    fontSize: '15px',
    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
   
    }
    const location = useLocation()
  const { testvalue,testtitle } = location.state
    return(
      <>
    <div className='services' display='flex'>
    <label>
  
      
    {testtitle}
    </label>
    
    {testvalue==='OMFB Individual Savings' && <div style={{servicestyle}} >
    <h1>OMFB Individual Savings  </h1>
      <br/> Whether you want to save towards a specific project or just want to set some money aside, this basic savings
            <br/>account is a good place to start.</div>}
      {testvalue==='OMFB Individual Current' && <div style={{servicestyle}} >
    <h1>OMFB Individual Current</h1>
      <br/> This is a transactional account that helps holders have access to efficient day-to-day financial services 
       <br/>The COT per mille is attractive while customized cheque book is issued to account holders. </div>}
      {testvalue==='OMFB Joint Savings' && <div style={{servicestyle}} >
    <h1>OMFB Joint Savings</h1>
      <br/> Trying to get a group savings for your business or family, this joint savings account would help you achieve your aims.
      </div>}
  {testvalue==='OMFB Joint Current' && <div style={{servicestyle}} >
    <h1>OMFB Joint Current </h1>
      <br/> The Joint Current is primarily for Clubs, Churches and Associations. It aids the body to financially monitor its savings
      <br/>or deposits. Our cheques can be cleared through our correspondent banks.
      <br/>This is a transactional account that helps holders have access to efficient day-to-day financial services.
      <br/> The COT per mille is attractive while customized cheque book is issued to account holders.
      </div>}
      {testvalue==='OMFB Mobile Banking' && <div style={{servicestyle}} >
    <h1>OMFB Mobile Banking</h1>
    <br/>This product saves our customers the stress of coming to the Bank for their deposits.  With 
    <br/>our secured and customized apparatus in place, our marketers now visit our various <br/>customers to collect their deposits for lodgement into any of their accounts with the 
    <br/>Bank.  This is done even at their door steps.
      </div>}
    
      {testvalue==='OMFB Target Savings Account' && <div style={{servicestyle}} >
    <h1>OMFB Target Savings Account </h1>
      <br/> This product is meant for meeting specific financial obligations like wedding, festive periods e.g. Christmas and othe
      <br/>projects like property renovation.
     </div>}
      {testvalue==='OMFB Fixed Deposit Account' && <div style={{servicestyle}} >
    <h1>OMFB Fixed Deposit Account</h1>
      <br/> The product offers attractive returns on investment. Interest rate is negotiable but above the normal savings
      <br/>account rate. The tenures are 3, 6, 9, 12 months and above.</div>}
      {testvalue==='OMFB Minor Account' && <div style={{servicestyle}} >
    <h1>OMFB Minor Account</h1>
      <br/> This product is to enhance investment into the future of children by parents. The parents operate the account
      <br/> through periodic deposits till such a time the child (the account owner) is old enough to operate the account.
    </div>}
      {testvalue==='Education Empowerment Account' && <div style={{servicestyle}} >
    <h1>Education Empowerment Account </h1>
      <br/> This product is aimed at improving the education level of the people. The account holder makes periodic deposits
      <br/>with the hope of meeting future financial challenges associated with educational attainment. 
    </div>}
    {testvalue==='Petty Trading Account' && <div style={{servicestyle}} >
    <h1>Petty Trading Account </h1>
      <br/> A product meant to inculcate the saving habits among the petty traders.  These people are usually covered by our
      <br/>mobile banking outfit.  No amount is too small or too big to deposit.  It can be used as collateral for micro credit loans.
    </div>}
    {testvalue==='OMFB Esusu Account' && <div style={{servicestyle}} >
    <h1>OMFB Esusu Account </h1>
      <br/> This product provides the avenue for our marketers to visit our customers with the aim of collecting agreed amounts
      <br/> on daily basis. The customers, therefore, make payment and/or contributions which serve as charge on the
      <br/>transaction at the end of the month.
    </div>}
    {testvalue==='Fund Transfers' && <div style={{servicestyle}} >
    <h1>Fund Transfers </h1>
      <br/> In collaboration with our corresponding Banks, Ohafia Microfinance Bank discourages its customers from carrying
      <br/> cash to different business locations with attendant risks. Customers lodge money in any of our correspondent banks
      <br/>either for themselves or on behalf of others and withdraw in Ohafia Microfinance Bank with ease.
    </div>}
    {testvalue==='Financial Advisory Services' && <div style={{servicestyle}} >
    <h1>Financial Advisory Services </h1>
      <br/> The bank provides financial advisory services for clients on accounting records and feasibility studies to enhance
      <br/> operational efficiency of their businesses.
    </div>}
    {testvalue==='Internet/ATM Alert Services' && <div style={{servicestyle}} >
    <h1>Internet/ATM Alert Services </h1>
      <br/> The bank has internet services and customers receive alert SMS on their mobile phones after every transaction.
      <br/> In addition, in line with the cashless policy of the Central Bank of Nigeria the bank provides ATM services for both the
      <br/> account holders of the bank and other banks nation-wide.
    </div>}
  
    </div>
    </>
    );}
