import React from 'react';
import '../../App.css';
import './Home.css';
import './Animate.css';

export default function Home() {
  return (
 
     <div className="slide_bg">
     <div className="slider">
 <div className="slide slide1">
   <div className="caption">
     <h2>Safe Banking</h2>
     <p>Never share your card pin, OTP or sensitive account details with anyone.
Also, Ohafia MfB will never ask you for any payment to access any of our services.
Be alert.</p>
   </div>
 </div>
 <div className="slide slide2">
   <div className="caption">
     <h2>SME Loans</h2>
     <p>Grow your small business with our SME Loan at competitive interest rates with no collateral required.

</p>
   </div>
 </div>
 <div className="slide slide3">
   <div className="caption">
     <h2>Micro Loans</h2>
     <p>Providing easy access to credit for micro businesses and members of low-income households.
</p>
   </div>
 </div>
 <div className="slide slide4">
   <div className="caption">
     <h2>Civil Servant Loans</h2>
     <p>Quick loans for Local,State and Federal Civil Servants; no collateral required, just your Salary account details.</p>
   </div>
 </div>
 <div className="slide slide5">
   <div className="caption">
     <h2>Fixed Deposit</h2>
     <p>Let your money work for you. 
      Fix it with Ohafia MFB and earn high yield interests on your deposit.</p>
   </div>
 </div> 
<div className="slide slide6">
   <div className="caption">
     <h2>Agric Loans</h2>
     <p>Grow your Agri-business with our Agricultural loan. Carefully designed for small-holder
farmers & players in the Agro value chain.</p>
    
   </div>
 </div>
</div>

   </div>
    
    
    
  );
}
