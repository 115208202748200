import React, { useState } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdownservice from './Dropdownservice';
import DropdownSub from './Dropdownsubsidiary';
import DropdownAbout from './Dropdownabout';
import Dropdownproduct from './Dropdownproduct';


import Ologo from '../images/Ologo.png';




function Navbar() {
  const [click,setClick] = useState( false);
  const [state, setDropDown] = useState({dropdown:false,about:false,subsidiary:false,service: false,product:false});
 

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

 
  return (
    <>
      <nav className='navbar'>
        <Link to='/' className='nav-item' onClick={closeMobileMenu}>
          <img src={Ologo}  alt='Home'style={{background:'green'}} className='navbar-logo'/> 
        
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className='nav-item' id='about'
           onMouseEnter={ ()=>{  
            if (window.innerWidth < 960) {
              setDropDown({dropdown:false,about:false,service:false,subsidiary:false});
            } else {
            
              setDropDown({dropdown:false,about:true,service:false,subsidiary:false});
            }}}
          onMouseLeave={
            ()=>{  
              
                setDropDown({dropdown:false,about:false,service:false,subsidiary:false});
             
              }
          }
          >
          <Link
              // to='/about'
              className='nav-links'
              onClick={closeMobileMenu}   
            >
              About
              {/* <i className='fas fa-caret-down'/> */}
            
            </Link>
            {state.about && <DropdownAbout />}
          </li>
          <li
            className='nav-item'
            onMouseEnter={ ()=>{  
              if (window.innerWidth < 960) {
                setDropDown({dropdown:false,about:false,service:false,subsidiary:false});
              } else {
              
                setDropDown({dropdown:true,about:false,service:true,subsidiary:false});
              }}}
            onMouseLeave={
              ()=>{  
                if (window.innerWidth < 960) {
                  setDropDown({dropdown:false,about:false,service:false,subsidiary:false});
                } else {
                
                  setDropDown({dropdown:false,about:false,service:false,subsidiary:false});
                }}
            }
          >
            <Link
              // to='/services'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Services 
              {/* <i className='fas fa-caret-down'/> */}
              
            </Link>
            {state.service && <Dropdownservice />}
          
          </li>
          <li className='nav-item' id='product'
          onMouseEnter={()=>{  
            if (window.innerWidth < 960) {
              setDropDown({...state,dropdown:false,service:false,product:false,subsidiary:false});
            } else {
            
              setDropDown({...state,dropdown:false,service:false,product:true,subsidiary:false});
            }}}
          onMouseLeave={()=>{  
            if (window.innerWidth < 960) {
              setDropDown({...state,dropdown:false,service:false,product:false,subsidiary:false});
            } else {
            
              setDropDown({...state,dropdown:false,service:false,product:false,subsidiary:false});
            }}}
          
          >
            
          <Link
              // to='/product'
              className='nav-links'
               onClick={closeMobileMenu}
             
            >
            Products 
            </Link>
            {state.product && <Dropdownproduct />}
          </li>
          <li className='nav-item'
           onMouseEnter={ ()=>{  
            if (window.innerWidth < 960) {
              setDropDown({dropdown:false,about:false,service:false,subsidiary:false,product:false});
            } else {
            
              setDropDown({dropdown:true,about:false,service:false,subsidiary:true,product:false});
            }}}
          onMouseLeave={
            ()=>{  
             
                setDropDown({dropdown:false,about:false,service:false,subsidiary:false,product:false});
             
            }
          }
          
          >
          <Link
              // to='/subsidiary'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Subsidiary
              {/* <i className='fas fa-caret-down'/> */}
            </Link>
            {state.subsidiary && <DropdownSub />}
          </li>
          
          <li>
            <Link
              to='/atmcard'
              className='nav-links-mobile'
              style={{background:'green'}}
              onClick={closeMobileMenu}
            >
            Request ATM Card
            </Link>
          </li>
          <li className='nav-item' id='contact-us'>
            <Link
              to='/contact-us'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Contact Us
            </Link>
          </li>
        </ul>
       
        <Button />
        
      </nav>
     
    </>
  );
}

export default Navbar;
