import React from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
// import  "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Navbar from './components/Navbar';


import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import ContactUs from './components/pages/ContactUs';
import Subsidiary from './components/pages/Subsidiary';
import ATMCard from './components/pages/ATMCard';
import About from './components/pages/About';
import Advert from './components/pages/Job_advert';
import Header from './common/Header';
import Footer from './common/Footer';
import Policy from './components/pages/Privacy'




function App() {
  return (
    <Router>
      <Header/>
      <Navbar/>
      <Routes>
        <Route path='/' exact element={<Home/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/products' element={<Products/>} />
        <Route path='/contact-us' element={<ContactUs/>} />
        <Route path='/subsidiary' element={<Subsidiary/>} /> 
        <Route path='/sign-up' element={<ATMCard/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/privacy' element={<Policy/>} />
        <Route path='/jobadvert' element={<Advert/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
