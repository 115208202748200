import React,{useState} from 'react';
import  './atmform.css'

export default function Subsidiary() {
  
    const [isHover,setIsHover]=useState(false);
    const  handleMouseEnter=()=>{
       setIsHover(true);
    }
    
    const  handleMouseLeave=()=>{
      setIsHover(false);
    }

    const marketing ={
     
      background: '#CCCCCC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      maxWidth: '80%',
      margin: '20px auto',
      }
  const divstyle={
    color:isHover ? 'blue' : 'green',
   fontFamilys:'san serif',
   padding: isHover ? '20px 100px':'6px 18px',
   transition: 'all 0.3s ease-out',
  backgroundColor: isHover? 'lightgreen':'lightgray',
   borderRadius: '50px',
   textDecoration:'none',
   width: isHover ? '70%':'50%',
   fontSize:isHover ? '1.3rem':'1rem',
   border: 'none',
  textAlign: 'flex',
  margin: '30px',
  }
  
  return (
    <>
      
      <p className='subsidiary' style={{fontSize:'130px'}}>OMFB DIGITAL WORLD</p>
      
      <p style={{color:"green"}}>
      WE OFFER THE FOLLOWING SERVICES:
      </p>
      <div   style={marketing} >
      <div style={divstyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        
      <p  >Photocopy(Black & White)</p>
      <p style={{margin:"5px"}}>Photocopy(Colour)</p>
      <p style={{margin:"5px"}}>Fast Internet Link</p>
      <p style={{margin:"5px"}}>Type Setting</p>
      <p style={{margin:"5px"}}>Online Examination Registration</p>
      <p style={{margin:"5px"}}>Checking of Examination Results</p>
      <p style={{margin:"5px"}}>Computer Training</p>
      <p style={{margin:"5px"}}>Graphic Design</p>
      <p style={{margin:"5px"}}>Typing,Scanning,Binding and Lamination of Documents</p>
      <p style={{fontSize:"13px",color:'brown'}}>contact: 09069570140 , 08032331854</p>
      </div>
      </div>
      
    </>
  );
}
