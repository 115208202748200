export const MenuItems = [
  {
    title: 'OMFB Individual Savings',
    path: '/services',
    msg:{ testvalue: "OMFB Individual Savings" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Individual Savings',
    path: '/services',
    msg:{ testvalue: "OMFB Individual Savings" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Individual Current',
    path: '/services',
    msg:{ testvalue: "OMFB Individual Current" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Joint Savings',
    path: '/services',
    msg:{ testvalue: "OMFB Joint Savings" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Joint Current',
    path: '/services',
    msg:{ testvalue: "OMFB Joint Current" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Mobile Banking',
    path: '/services',
    msg:{ testvalue: "OMFB Mobile Banking" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Target Savings Account',
    path: '/services',
    msg:{ testvalue: "OMFB Target Savings Account" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Fixed Deposit Account',
    path: '/services',
    msg:{ testvalue: "OMFB Fixed Deposit Account" },
    cName: 'dropdown-link'
  },
  {
    title: 'Education Empowerment Account ',
    path: '/services',
    msg:{ testvalue: "Education Empowerment Account" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Esusu Account',
    path: '/services',
    msg:{ testvalue: "OMFB Esusu Account" },
    cName: 'dropdown-link'
  },
  {
    title: 'Petty Trading Account',
    path: '/services',
    msg:{ testvalue: "Petty Trading Account" },
    cName: 'dropdown-link'
  },
  {
    title: 'Fund Transfers ',
    path: '/services',
    msg:{ testvalue: "Fund Transfers" },
    cName: 'dropdown-link'
  },
  {
    title: 'OMFB Minor Account ',
    path: '/services',
    msg:{ testvalue: "OMFB Minor Account" },
    cName: 'dropdown-link'
  },
  {
    title: 'Financial Advisory Services',
    path: '/services',
    msg:{ testvalue: "Financial Advisory Services" },
    cName: 'dropdown-link'
  },
  {
    title: 'Internet/ATM Alert Services',
    path: '/services',
    msg:{ testvalue: "Internet/ATM Alert Services" },
    cName: 'dropdown-link'
  }   
    

];
