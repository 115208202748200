export const MenuItems = [
  {
    title: 'Preamble',
    path: '/about',
    msg:{ testvalue: "Preamble" },
    cName: 'dropdown-link'
  },
  {
    title: 'Directors',
    path: '/about',
    msg:{ testvalue: "Directors" },
    cName: 'dropdown-link'
  },
  {
    title: 'Profile',
    path: '/about',
    msg:{ testvalue: "Profile" },
    cName: 'dropdown-link'
  },
 
  {
    title: 'Values & Philosophy',
    path: '/about',
    msg:{ testvalue: "Values & Philosophy" },
    cName: 'dropdown-link'
  },
  {
    title: 'Management Team',
    path: '/about',
    msg:{ testvalue: "Management Team" },
    cName: 'dropdown-link'
  },

  {
    title: 'Chairman \'s AGM Speech',
    path: '/about',
    msg:{ testvalue: "Chairmans AGM Speech" },
    cName: 'dropdown-link'
  },
  {
    title: 'Financial Results',
    path: '/about',
    msg:{ testvalue: "Financial Results" },
    cName: 'dropdown-link'
  },

  

];



