
import React,{useRef  } from 'react';
import '../../App.css';
import  '../Button.css'
import  './atmform.css'
import emailjs from '@emailjs/browser';

import Swal from 'sweetalert2';
// npm i emailjs-com semantic-ui-react semantic-ui-css sweetalert2

const SERVICE_ID = "service_bl6h519";
const TEMPLATE_ID = "template_t3yy5kr";
const PUBLIC_KEY = "YfZsZhMnidOuHO4P9";


export default function ATMCard() {
  const form = useRef();
  // const [name,setName]=useState('');
  // const [nuban,setNuban]=useState('');
  // const handleNameChange=(event)=>{
  //  setName(event.target.value)
  // }
  // const handleNubanChange=(event)=>{
  //   setNuban(event.target.value)
  //  }
  const handleOnSubmit=(e)=>{
// alert(name+' and '+nuban);
e.preventDefault();
emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
  .then((result) => {
    console.log(result.text);
    Swal.fire({
      icon: 'success',
      title: 'Message Sent Successfully'
    })
  }, (error) => {
    console.log(error.text);
    Swal.fire({
      icon: 'error',
      title: 'Ooops, something went wrong',
      text: error.text,
    })
  });
 e.target.reset()
  }
   return (
    <>
    <h2 style={{textAlign:'center'}}>Apply for ATM Card<br/> <i  style={{fontSize:'0.8rem',color:'green'}}>Items marked with asterisks(*) are compulsory</i></h2>
    <div className='atm' textalign='center'>
  <form  ref={form} className='form' >
     <h3>Customer's Name<i  style={{fontSize:'1rem',color:'red'}}>*</i></h3> <input type={'text'}  placeholder={'Type your name here'}   name='name'/>
     <h3>Nuban A/C No<i  style={{fontSize:'1rem',color:'red'}}>*</i></h3> <input type={'text'}    placeholder={'Type your 10digit A/C No'}  name='nuban'/>
     <h3>phone<i  style={{fontSize:'1rem',color:'red'}}>*</i></h3><input type="phone" name="phone" />
     <h3>email<i style={{fontSize:'1rem',color:'red'}}></i></h3><input type="email" name="email" />
     <h3>Comment<i style={{fontSize:'1rem',color:'red'}}></i></h3><textarea name="message" placeholder='Type optional comment here' />

     <div><i><img src='' alt=''></img></i></div>
     <div> <h3><button className='btn'  onClick={handleOnSubmit} >Submit</button></h3></div>

     

  </form >
  </div>
  </>
  )
}
