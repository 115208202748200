import React from 'react';
import { Link } from 'react-router-dom';
import facebook from '../images/sm/facebook.png';
import twitter from '../images/sm/twitter.png';
import linkedin from '../images/sm/linkedin.png';


const Footer=()=>{
  const footerstyle= {
     
    background: '#AAAAAA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px 18px',
    textAlign: 'center',
    maxWidth: '100%',
    margin: '20px auto',
    fontSize:'13px',
    }
return(
<div className='App-footer' style={footerstyle}>
 © 2022 Ohafia Microfinance Bank Plc <i style={{margin:'2%'}} /><Link to='/privacy' ><i style={{margin:'-3%'}} />PRIVACY STATEMENT </Link><Link to='/policy'>|| TERMS AND CONDITIONS</Link><Link to="http://facebook.com" style={{margin:'1%'}}>
    <img src={facebook} width={32} height={26} alt={'facebook'}/></Link><Link to="http://twitter.com" style={{margin:'1%'}}>
  <img src={twitter} alt={'twitter'} width={32}  height={26} /></Link> <Link to="http://linkedin.com" style={{margin:'1%'}}><img src={linkedin} alt={'facebook'} width={32}  height={26} /></Link>
      
</div>


)



}
export default Footer