import React from 'react';
import '../../App.css';


export default function Job_advert() {

 


    const servicestyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '10vh',
      width: '80%', 
      padding: '5%', 
      margin: '2%', 
      fontSize: '2vw', 
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  
      // Media query for mobile phones
      '@media (max-width: 768px)': {
          width: '80%', 
          padding: '2%', 
          margin: '5%', 
          fontSize: '2vw', 
         
          },
  };
   

    return(
    <div className='jobadvert'  style={servicestyle}> 
    <h1>Not Accepting Any Applications Yet</h1>
      <br/> Ohafia MFB does not currently have any vacancies available.
      <br/> You can check back later.
    </div>
  
    );
  }
